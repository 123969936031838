/* General Styles */
/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #012524;
  margin: 50px;
} */

/* Navigation Styles */
/* nav {
  background-color: #012524;
  padding: 1em 0;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 1em;
}

nav ul li a,
nav ul li .logout-button {
  color: white;
  text-decoration: none;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

nav ul li a:hover,
nav ul li .logout-button:hover {
  text-decoration: underline;
}

nav ul li .logout-button {
  padding: 0.5em 1em;
}*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');


html,
body,
#root {
	/* height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center; */
}

body {
  font-family: 'Open Sans', sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
	width: 100%;
}

.app {
	display: flex;
	text-align: center;
	/* align-items: center;
	justify-content: center; */
	background-image: url("../assets/hijack-bg-desktop.webp");
	background-size: cover;
	background-repeat: no-repeact;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.descope-form {
  /* width: 400px; */
  /* max-width: 450px; */
  /* margin: 1rem; */
  border-radius: 6px;
  overflow: auto;
}

.page {
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center; */
  /* align-items: center;
  height: 100%;
  width: 100%; */
  /* flex: 1; */
  min-height: 100%;
  display: flex;
  min-width: 100%;
  align-items: center;
}

/* .app-content {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	margin-top: 150px;
} */

/* .descope-login-container {
  margin: 1rem;
  max-width: 450px;
} */

/* .welcome-title {
	margin-bottom: 0px;
	font-family: 'JetBrains Mono';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 128%;
	letter-spacing: 0.6px;
	color: #0082b5;
}

.example-title {
	margin-bottom: 0px;
	font-family: 'JetBrains Mono';
	font-style: normal;
	font-size: 20px;
	letter-spacing: 0.6px;
}

.example {
	border: 2px solid;
	min-width: 350px;
	border-radius: 100px;
	border-color: #0082b5;
	padding: 16px 32px;
	background-color: #f6fbff;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	word-break: break-all;
}

.copy-icon {
	margin-left: 6px;
	height: 100%;
}

.text-body {
	font-size: 20px;
	display: inline-block;
}

h1 {
	margin: 0px;
	font-weight: 800;
	font-size: 32px;
	line-height: 128%;
} */

/* p {
	font-family: 'Barlow';
	font-weight: 400;
	font-style: normal;
	line-height: 160%;
	text-align: center;
	display: flex;
	letter-spacing: 0.6px;
	color: rgba(0, 0, 0, 0.87);
	margin-top: 8px;
} */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
	.app-content {
		width: 90%;
	}

	.descope-container {
		margin-left: 16px;
		margin-right: 16px;
	}

	.example {
		min-width: fit-content;
	}
} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
	.app-content {
		width: 80%;
	}
	.example {
		min-width: fit-content;
	}
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
	.app-content {
		width: 55%;
	}
	.example {
		min-width: 350px;
	}
} */
